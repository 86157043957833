<template>
  <div class="base">
    <div data-anima="top">
      <h1 :class="`${clubVersion === 'v2' ? `font-v2 default-font-${defaultFont}` : ''}`">Aparência do sistema</h1>
      <div class="line"></div>
      <Item
        title="Logotipo"
        @remove="remove"
        :value="null"
        param="logo"
        descricao=""
      >
        <template v-slot:icon>
          <customIcon />
        </template>
        <template v-slot:sub>
          <div
            style="cursor: pointer"
            @click="showModalUploadLogo"
            v-if="!logo"
          >
            <b-form-file
              class="mt-3 input-file"
              v-model="logo"
              plain
            ></b-form-file>
            {{ logo }}
          </div>
          <div class="image" @click="showModalUploadLogo" v-else>
            <img :src="logo" alt="logo" />
          </div>
          <div>
            <span
              >Dimensão Recomendada: 100x100
              {{ logo ? ' - Clique para selecionar nova imagem' : '' }}</span
            >
          </div>
        </template>
      </Item>
      <Item
        v-if="clubVersion === 'v1'"
        title="Imagotipo para Menu Aberto (Opcional)"
        :value="null"
        param="logomarca"
        @remove="remove"
        descricao=""
      >
        <template v-slot:icon>
          <customIcon />
        </template>
        <template v-slot:sub>
          <div
            style="cursor: pointer, display: flex;"
            @click="showModalUploadLogomarca"
            v-if="!logomarca"
          >
            <b-form-file
              class="mt-3 input-file"
              v-model="logomarca"
              plain
            ></b-form-file>
          </div>
          <div class="image" @click="showModalUploadLogomarca" v-else>
            <img :src="logomarca" alt="logomarca" />
          </div>
          <div>
            <span
              >Dimensão Recomendada: 300x90
              {{
                logomarca ? ' - Clique para selecionar nova imagem' : ''
              }}</span
            >
          </div>
        </template>
      </Item>
      <Item
        title="Imagem de fundo de login"
        :value="null"
        @remove="remove"
        param="login_background_image"
        descricao=""
      >
        <template v-slot:icon>
          <customIcon />
        </template>
        <template v-slot:sub>
          <div
            style="cursor: pointer"
            @click="showModalUploadBackground"
            v-if="!login_background_image"
          >
            <b-form-file
              class="mt-3 input-file"
              v-model="login_background_image"
              plain
            ></b-form-file>
          </div>
          <div class="image" @click="showModalUploadBackground" v-else>
            <img :src="login_background_image" alt="login_background_image" />
          </div>
          <div>
            <span
              >Dimensão Recomendada: 1920 x 1080
              {{
                login_background_image
                  ? ' - Clique para selecionar nova imagem'
                  : ''
              }}</span
            >
          </div>
        </template>
      </Item>
      <Item
        title="Cor principal do painel"
        :value="null"
        param="rating_home"
        descricao=""
      >
        <template v-slot:icon>
          <customIcon />
        </template>
        <template v-slot:sub>
          <div class="flex">
            <v-swatches
              v-model="main_color"
              :swatches="swatches"
              row-length="1"
              @input="changedColor"
              inline
            >
            </v-swatches>
            <div class="ml-5 flex">
              <div><span>Personalizar Cor:</span></div>
              <InputColorPicker
                class="ml-3"
                @change="changedColor"
                v-model="main_color"
              />
            </div>
          </div>
        </template>
      </Item>
      <Item v-if="clubVersion === 'v2'"

        title="Definir Curso em Destaque"
        descricao="Selecione o curso que ficara em destaque no seu painel."
        :value="null"
      >
        <template v-slot:icon>
          <customIcon />
        </template>
        <template v-slot:sub>
          <div class="mt-3">
            <b-form-select
              placeholder="Selecione um Curso"
              v-model="featured_course"
              :options="courseOptions"
              @change="updateCourse"
            >
            </b-form-select>
          </div>
        </template>
      </Item>
      <Item
        title="Editar nome, descrição e subdomínio do painel"
        :value="null"
        @update="update"
        param="termsOfUseActivated"
        descricao=""
      >
        <template v-slot:icon>
          <customIcon />
        </template>
        <template v-slot:sub>
          <div class="mt-3">
            <BaseButton @click="openModalConfigPainel" variant="link-info"
              >Clique para editar informações do painel</BaseButton
            >
          </div>
        </template>
      </Item>
      <Item
        title="Ocultar cursos e categorias"
        :value="hide_courses"
        @update="update"
        param="hide_courses"
        descricao="Se ativado e o aluno não tiver o curso, ele não sera apresentado no sistema."
      >
        <template v-slot:icon>
          <categoryIcon />
        </template>
      </Item>
      <Item
        v-if="clubVersion === 'v1'"
        title="Capas dos cursos na vertical (Modelo capa de filme)"
        :value="home_vertical"
        @update="update"
        param="home_vertical"
        descricao="Se essa opção estiver ativa, os cursos serão listados verticalmente, sera necessario subir as capas dos cursos novamente."
      >
        <template v-slot:icon>
          <capaIcon />
        </template>
      </Item>
      <Item v-if="clubVersion === 'v2'"
        title="Proporção da capa"
        description="Selecione a proporção da capa dos cursos"
        :value="null"
      >
        <template v-slot:icon>
          <capaIcon />
        </template>
        <template v-slot:sub>
          <div class="cover-proportion-container">
            <div :style="`
              height: ${proportion.height / 3}px;
              width: ${proportion.width / 3}px;
              aspect-ratio: ${proportion.value};`" 
              :key="proportion.value" 
              @click="updateCoverProportion(proportion)"
              class="cover-proportion-item"
              :class="{ 'cover-proportion-selected': proportion.value === cover_proportion.value }"
              v-for="proportion in proportionOptions"
            >
              <span class="cover-proportion-text">
                {{ proportion.text }}
              </span> 
              <span class="cover-proportion-text">
                ( {{ proportion.value }} )
              </span>
            </div>
          </div>
        </template>
      </Item>
      <Item
        title="Modo Carrossel (Desktop)"
        :value="home_carousel"
        @update="update"
        param="home_carousel"
        descricao="Se essa opção estiver ativa, os cursos serão listados em uma linha na Desktop."
      >
        <template v-slot:icon>
          <gridIcon />
        </template>
      </Item>
      <Item
        title="Listar cursos fora das categorias"
        :value="without_category"
        @update="update"
        param="without_category"
        descricao="Se essa opção estiver ativa, os modulos serão listados na home."
      >
        <template v-slot:icon>
          <listIcon />
        </template>
      </Item>
      <Item
        title="Mostrar disponibilidade do curso"
        descricao="Se esta opção estiver ativa, no card será mostrada a disponibilidade do curso."
        param="is_available_course"
        :value="is_available_course"
        @update="update"
      >
        <template v-slot:icon>
          <checkIcon />
        </template>
        <template v-slot:sub>
          <section v-if="is_available_course">
            <b-row class="mt-3">
              <b-col cols="3" md="2" class="flex align-items-center">
                <b-form-group label="Disponível" label-for="available_color">
                  <InputColorPicker
                    class="ml-3"
                    :class="blackAndWhiteClassAvailable"
                    @change="changeAvailableColor"
                    id="available_color"
                    v-model="available_color"
                    type="color"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6" md="2" class="flex">
                <is-available
                  v-if="!loading"
                  :has-access="true"
                  :is-example="true"
                  :example-available-color="available_color"
                ></is-available>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3" md="2" class="flex align-items-center">
                <b-form-group
                  label="Indisponível"
                  label-for="not_available_color"
                >
                  <InputColorPicker
                    class="ml-3"
                    :class="blackAndWhiteClassNotAvailable"
                    @change="changeNotAvailableColor"
                    id="not_available_color"
                    v-model="not_available_color"
                    type="color"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6" md="2" class="flex align-items-center">
                <is-available
                  v-if="!loading"
                  :has-access="false"
                  :is-example="true"
                  :example-not-available-color="not_available_color"
                ></is-available>
              </b-col>
            </b-row>
          </section>
        </template>
      </Item>
      <Item v-if="clubVersion === 'v2'"
        title="Banner do topo do Painel(Desktop)"
        :value="null"
        @remove="remove"
        param="panel_background_image"
        descricao=""
      >
        <template v-slot:icon>
          <customIcon />
        </template>
        <template v-slot:sub>
          <div
            style="cursor: pointer"
            @click="showModalUploadBackgroundPanel"
            v-if="!panel_background_image"
          >
            <b-form-file
              class="mt-3 input-file"
              v-model="panel_background_image"
              plain
            >
            </b-form-file>
          </div>
          <div class="image" @click="showModalUploadBackgroundPanel" v-else>
            <img :src="panel_background_image" alt="panel_background_image" />
          </div>
          <div>
            <span>
              Dimensão Recomendada: 1380 x 500
              {{
                panel_background_image
                  ? ' - Clique para selecionar nova imagem'
                  : ''
              }}
            </span>
          </div>
        </template>
      </Item>
      <Item v-if="clubVersion === 'v2'"
        title="Banner do topo do Painel(Mobile - Opcional)"
        :value="null"
        @remove="remove"
        param="panel_background_image_mobile"
        descricao=""
      >
        <template v-slot:icon>
          <customIcon />
        </template>
        <template v-slot:sub>
          <div
            style="cursor: pointer"
            @click="showModalUploadBackgroundPanelMobile"
            v-if="!panel_background_image_mobile"
          >
            <b-form-file
              class="mt-3 input-file"
              v-model="panel_background_image_mobile"
              plain
            >
            </b-form-file>
          </div>
          <div class="image" @click="showModalUploadBackgroundPanelMobile" v-else>
            <img :src="panel_background_image_mobile" alt="panel_background_image_mobile" />
          </div>
          <div>
            <span>
              Dimensão Recomendada: 465 x 400
              {{
                panel_background_image_mobile
                  ? ' - Clique para selecionar nova imagem'
                  : ''
              }}
            </span>
          </div>
        </template>
      </Item>

      <Item v-if="clubVersion === 'v2'"
        title="Definir fonte padrão"
        descricao="Selecione a fonte que será utilizada como padrão no painel."
        :value="null"
      >
        <template v-slot:icon>
          <customIcon />
        </template>
        <template v-slot:sub>
          <div class="mt-3">
            <b-form-select
              placeholder="Selecione uma fonte"
              v-model="default_font"
              :options="font_options"
              @change="updateFonts"
            >
            </b-form-select>
          </div>
        </template>
      </Item>
      
      <Item
        title="Tema do sistema é fixo"
        :value="theme_fixed_painel"
        @update="update"
        param="theme_fixed_painel"
        descricao="Se essa opção estiver ativa o tema do painel não podera ser alterado pelo aluno."
      >
        <template v-slot:icon>
          <themeIcon />
        </template>
        <template v-slot:sub>
          <div v-if="theme_fixed_painel">
            <b-form-radio-group
              id="radio-group-2"
              class="mt-3"
              v-model="theme_fixed_painel_color"
              name="Escolha um tema"
              @change="changeValueThemeFixed"
            >
              <b-form-radio value="light">Tema Claro</b-form-radio>
              <b-form-radio value="dark">Tema Escuro</b-form-radio>
            </b-form-radio-group>
          </div>
        </template>
      </Item>
      <Item
        title="Versão do club"
        :value="null"
        descricao="Escolha qual versão do club deseja utilizar"
        v-if="can_change_version"
      >
        <template v-slot:icon>
          <customIcon />
        </template>
        <template v-slot:sub>
          <div>
            <b-form-radio-group
              class="mt-3"
              v-model="club_version"
              name="Escolha uma versão"
              @change="changeClubVersion"
            >
              <b-form-radio value="v1">Versão 1</b-form-radio>
              <b-form-radio value="v2">Versão 2</b-form-radio>
            </b-form-radio-group>
          </div>
        </template>
      </Item>
      <Item
        title="Exibição de arquivos complementares da aula"
        param="download_style_file"
        :value="null"
        descricao="Listado em baixo da aula ou no menu acima da aula"
        v-if="clubVersion === 'v2'"
      >
        <template v-slot:icon>
          <customIcon />
        </template>
        <template v-slot:sub>
          <div>
            <b-form-radio-group
              id="radio-group-2"
              class="mt-3"
              v-model="download_style_file"
              name=""
              @change="downloadStyleChange"
            >
              <b-form-radio value="menu">Menu</b-form-radio>
              <b-form-radio value="list">Lista</b-form-radio>
            </b-form-radio-group>
          </div>
        </template>
      </Item>
    </div>
    <ModalConfigPainel />
    <ModalUpload />
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import MetaService from '@/services/resources/MetaService';
const serviceMeta = MetaService.build();
import { mapActions } from 'vuex';

import Item from './Item.vue';

import categoryIcon from './icons/category.vue';
import customIcon from './icons/custom.vue';
import capaIcon from './icons/capa.vue';
import gridIcon from './icons/grid.vue';
import listIcon from './icons/list.vue';
import themeIcon from './icons/theme.vue';
import checkIcon from './icons/check.vue';

import ModalConfigPainel from '@/components/ModalConfigPainel.vue';
import ModalUpload from '@/components/ModalUpload.vue';
import InputColorPicker from 'vue-native-color-picker';
import VSwatches from 'vue-swatches';
import 'vue-swatches/dist/vue-swatches.css';
import IsAvailable from '@/components/home/IsAvailable.vue';
import CourseService from '@/services/resources/CourseService';
const serviceCourse = CourseService.build();

export default {
  components: {
    Item,
    InputColorPicker,
    VSwatches,
    customIcon,
    ModalUpload,
    capaIcon,
    gridIcon,
    themeIcon,
    listIcon,
    categoryIcon,
    checkIcon,
    ModalConfigPainel,
    IsAvailable,
  },
  data() {
    return {
      main_color: '#00E4A0',
      swatches: [
        ['#00E4A0'],
        ['#2133D2'],
        ['#FF0C37'],
        ['#FFCB37'],
        ['#2ED7EE'],
        ['#5D21D2'],
        ['#5A68DF'],
        ['#FF37D3'],
      ],
      without_category: false,
      home_carousel: false,
      home_vertical: false,
      hide_courses: false,
      theme_fixed_painel: false,
      theme_fixed_painel_color: 'dark',
      club_version: 'v1',
      login_background_image: '',
      login_background_image_id: null,
      panel_background_image: '',
      panel_background_image_mobile: '',
      panel_background_image_id: null,
      panel_background_image_mobile_id: null,
      logomarca: '',
      logo: '',
      is_available_course: false,
      available_color: '#2133d2',
      not_available_color: '#ffcb37',
      loading: false,
      AllCourses: [],
      courseSelect: '',
      featured_course: '',
      can_change_version: true,
      cover_proportion: '',
      proportionOptions: [
        {
          text: "235 x 305",
          width: 235,
          height: 305,
          value: "47:61"
        },
        {
          text: "155 x 305",
          width: 155,
          height: 305,
          value: "31:61"
        },
        {
          text: "320 x 190",
          width: 320,
          height: 190,
          value: "16:9"
        },
        {
          text: "325 x 435",
          width: 325,
          height: 435,
          value: "13:17"
        },
        {
          text: "275 x 385",
          width: 275,
          height: 385,
          value: "11:17"
        },
        {
          text: "380 x 210",
          width: 380,
          height: 210,
          value: "38:21"
        }
      ],
      default_font: null,
      font_options: [
        {
          text: 'Montserrat Alternates',
          value: 'montserrat-alternates',
        },
        {
          text: 'Aclonica',
          value: 'aclonica',
        },
        {
          text: 'Bree Serif',
          value: 'bree-serif',
        },
        {
          text: 'Playfair Display',
          value: 'playfair-display',
        },
        {
          text: 'EB Garamond',
          value: 'eb-garamond',
        },
        {
          text: 'Poppins',
          value: 'poppins',
        }
      ],
      download_style_file: 'menu',
    };
  },
  computed: {
    defaultFont() {
      return localStorage.default_font;
    },
    theme() {
      return this.$store.state.globalSettings.theme;
    },
    clubVersion() {
      return this.$store.state.globalSettings.clubVersion;
    },
    blackAndWhiteClassNotAvailable() {
      return {
        'black-selected':
          this.not_available_color === '#ffffff' ||
          this.not_available_color === '#000000',
        'white-selected':
          this.not_available_color === '#ffffff' ||
          this.not_available_color === '#000000',
      };
    },
    blackAndWhiteClassAvailable() {
      return {
        'black-selected':
          this.available_color === '#ffffff' ||
          this.available_color === '#000000',
        'white-selected':
          this.available_color === '#ffffff' ||
          this.available_color === '#000000',
      };
    },
    courseOptions() {
      return [
        { text: 'Sem destaque', value: 0 },
        ...this.AllCourses.map(course => ({
          text: course.title,
          value: course.id,
        })),
      ];
    }
  },
  methods: {
    ...mapActions(['fetchMetas']),
    getKeys(key) {
      var keys = [];
      if (key) {
        keys = [key];
      } else {
        keys = [
          'main_color',
          'without_category',
          'home_carousel',
          'home_vertical',
          'hide_courses',
          'theme_fixed_painel',
          'theme_fixed_painel_color',
          'login_background_image',
          'panel_background_image',
          'panel_background_image_mobile',
          'logomarca',
          'logo',
          'club_version',
          'is_available_course',
          'available_color',
          'not_available_color',
          'featured_course',
          'can_change_version',
          'cover_proportion',
          'default_font',
          'download_style_file'
        ];
      }
      var result = '';
      for (let i = 0; i < keys.length; i++) {
        if (keys.length - 1 == i) {
          result += `keys[]=${keys[i]}`;
        } else {
          result += `keys[]=${keys[i]}&`;
        }
      }
      serviceMeta
        .search(result)
        .then((resp) => {
          if(resp.cover_proportion !== undefined) {
            this.cover_proportion = JSON.parse(resp.cover_proportion);
            localStorage.setItem('coverProportion', JSON.stringify(resp.cover_proportion));
          }
          if (resp.download_style_file) {
            this.download_style_file = resp.download_style_file;
          }
          if (resp.default_font !== undefined) {
            localStorage.default_font = resp.default_font;
            this.default_font = resp.default_font;
          }
          if (resp.main_color !== undefined) {
            this.main_color = resp.main_color;
          }
          if (resp.login_background_image !== undefined) {
            if (resp.login_background_image) {
              this.login_background_image = resp.login_background_image.cdn_url;
            }
          }
          if (resp.panel_background_image !== undefined) {
            this.panel_background_image = resp.panel_background_image?.cdn_url;
          }
          if (resp.panel_background_image_mobile !== undefined) {
            this.panel_background_image_mobile = resp.panel_background_image_mobile?.cdn_url;
          }
          if (resp.logomarca !== undefined && this.club_version === 'v1') {
            this.logomarca = resp.logomarca;
          }
          if (resp.logo !== undefined) {
            this.logo = resp.logo;
          }
          if (resp.without_category !== undefined) {
            if (
              resp.without_category === 'off' ||
              resp.without_category === null
            ) {
              this.without_category = false;
            } else {
              this.without_category = true;
            }
          }
          if (resp.home_vertical !== undefined) {
            if (resp.home_vertical === 'off' || resp.home_vertical === null) {
              this.home_vertical = false;
            } else {
              this.home_vertical = true;
            }
          }
          if (resp.home_carousel !== undefined) {
            if (resp.home_carousel === 'off' || resp.home_carousel === null) {
              this.home_carousel = false;
            } else {
              this.home_carousel = true;
            }
          }
          if (resp.hide_courses !== undefined) {
            if (resp.hide_courses === null || resp.hide_courses === 'on') {
              this.hide_courses = true;
            } else {
              this.hide_courses = false;
            }
          }
          if (resp.theme_fixed_painel !== undefined) {
            if (resp.theme_fixed_painel === 'off') {
              this.theme_fixed_painel = false;
            } else {
              this.theme_fixed_painel = true;
            }
          }
          if (resp.club_version === 'v1') {
            this.club_version = 'v1';
          } else if (resp.club_version === 'v2') {
            this.club_version = 'v2';
          }
          if (resp.can_change_version !== undefined) {
            if (
              resp.can_change_version === 'off' ||
              resp.can_change_version === null
            ) {
              this.can_change_version = false;
            } else {
              this.can_change_version = true;
            }
          }
          if (resp.theme_fixed_painel_color !== undefined) {
            if (resp.theme_fixed_painel_color !== null) {
              this.theme_fixed_painel_color = resp.theme_fixed_painel_color;
            } else {
              this.changeValueThemeFixed('light');
            }

            this.$root.$emit('loadOff');

            if (this.club_version != 'v2') {
              document.documentElement.setAttribute(
                'data-theme',
                this.theme_fixed_painel_color,
              );

              this.$root.$emit('loadTheme');
              this.$root.$emit('loadsettheme');
            }
          }

          if (resp.is_available_course !== undefined) {
            this.is_available_course =
              resp.is_available_course === 'on' ?? false;
          }
          if (resp.available_color !== undefined) {
            this.available_color = resp.available_color;
          }
          if (resp.not_available_color !== undefined) {
            this.not_available_color = resp.not_available_color;
          }
          if (resp.featured_course !== undefined) {
            this.featured_course = resp.featured_course;
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    downloadStyleChange() {
      let data = {
        id: 'download_style_file',
        value: this.download_style_file,
      };
      serviceMeta.postID(data).then(() => {
        this.mensageUpdate();
        this.getKeys('download_style_file');
      });
    },
    mensageUpdate() {
      this.$bvToast.toast('Configuração atualizada com sucesso!', {
        title: 'Atualizado!',
        variant: 'success',
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    update(item) {
      let data = {
        id: item[1],
        value: item[0] ? 'on' : 'off',
      };

      serviceMeta.postID(data).then(() => {
        this.mensageUpdate();
        this.getKeys(item[1]);
      });
    },
    updateCourse() {
      let data = {
        id: 'featured_course',
        value: this.featured_course,
      };
      serviceMeta.postID(data).then(() => {
        this.mensageUpdate();
        this.getKeys(data.id);
      });
    },
    updateCoverProportion(cover_proportion) {
      let data = {
        id: 'cover_proportion',
        value: JSON.stringify(cover_proportion),
      };
      serviceMeta.postID(data).then(() => {
        this.mensageUpdate();
        this.getKeys(data.id);
      });
    },
    updateFonts() {
      let data = {
        id: 'default_font',
        value: this.default_font,
      };
      serviceMeta.postID(data).then(async () => {
        this.$store.commit('REMOVE_META', 'default_font'); 
        await this.fetchMetas(['default_font']);
        this.mensageUpdate();
        this.getKeys(data.id);
      });
    },
    changeValueThemeFixed(theme) {
      let data = {
        id: 'theme_fixed_painel_color',
        value: theme,
      };

      Cookies.set('themeClub', theme, { expires: 365 });
      this.$store.commit('ClubTheme', theme);

      document.documentElement.setAttribute('data-theme', theme);
      this.$root.$emit('loadTheme');
      this.$root.$emit('loadsettheme');

      serviceMeta.postID(data).then(() => {
        this.mensageUpdate();
        this.getKeys('theme_fixed_painel_color');
      });
    },

    changeClubVersion(version) {
      let data = {
        id: 'club_version',
        value: version,
      };

      if (version === 'v2') {
        Cookies.set('clubVersion', 'v2', { expires: 365 });
        this.$store.commit('clubVersion', 'v2');
        this.club_version = 'v2';
      } else {
        Cookies.set('clubVersion', 'v1', { expires: 365 });
        this.$store.commit('clubVersion', 'v1');
        this.club_version = 'v1';
      }

      serviceMeta.postID(data).then(() => {
        this.mensageUpdate();
        this.getKeys('club_version');
      });
    },
    changedColor() {
      var data = {
        id: 'main_color',
        value: this.main_color,
      };
      serviceMeta.postID(data).then(() => {
        if (this.main_color !== null) {
          const d = document;
          d.documentElement.setAttribute('data-color', this.main_color);
          document.documentElement.style.setProperty(
            '--maincolor',
            this.main_color,
          );
          document.documentElement.style.setProperty(
            '--maincolorn',
            this.$func.convertColor(this.main_color, 210),
          );
          document.documentElement.style.setProperty(
            '--maincolortrans',
            this.main_color + '0f',
          );
        }
        this.mensageUpdate();
        this.getKeys('main_color');
        this.$root.$emit('changedmaincolor', this.color);
        this.$root.$emit('loadOff');
      });
    },
    updateLogoPainel() {
      var data = {
        id: 'logo',
        value: this.logo,
      };
      serviceMeta.postID(data).then(() => {
        this.getKeys('logo');
        this.mensageUpdate();
        this.$root.$emit('changedlogo');
      });
    },
    updateLogomarcaPainel() {
      var data = {
        id: 'logomarca',
        value: this.logomarca,
      };
      serviceMeta.postID(data).then(() => {
        this.getKeys('logomarca');
        this.mensageUpdate();
        this.$root.$emit('changedlogomarca');
      });
    },
    updateBackgroundLogin() {
      var data = {
        id: 'login_background_image',
        value: this.login_background_image_id,
      };
      serviceMeta.postID(data).then(() => {
        this.mensageUpdate();
        this.getKeys('login_background_image');
      });
    },
    updateBackgroundPanel() {
      var data = {
        id: 'panel_background_image',
        value: this.panel_background_image_id,
      };
      serviceMeta.postID(data).then(() => {
        this.mensageUpdate();
        this.getKeys('panel_background_image');
      });
    },
    updateBackgroundPanelMobile() {
      var data = {
        id: 'panel_background_image_mobile',
        value: this.panel_background_image_mobile_id,
      };
      serviceMeta.postID(data).then(() => {
        this.mensageUpdate();
        this.getKeys('panel_background_image_mobile');
      });
    },
    remove(item) {
      var data = {
        id: item,
        value: null,
      };
      serviceMeta.postID(data).then(() => {
        this.mensageUpdate();
        this.getKeys(item);
      });
    },
    openModalConfigPainel() {
      this.$root.$emit('openeditproject');
      this.$root.$emit('bv::show::modal', 'modal-config-painel', '#btnShow');
    },
    showModalUploadLogo() {
      this.$root.$emit('logopainel');
      this.$root.$emit('bv::show::modal', 'modal-upload', '#btnShow');
    },
    showModalUploadLogomarca() {
      this.$root.$emit('logomarcapainel');
      this.$root.$emit('bv::show::modal', 'modal-upload', '#btnShow');
    },
    showModalUploadBackground() {
      this.$root.$emit('backgroundlogin');
      this.$root.$emit('bv::show::modal', 'modal-upload', '#btnShow');
    },
    showModalUploadBackgroundPanel() {
      this.$root.$emit('backgroundpanel');
      this.$root.$emit('bv::show::modal', 'modal-upload', '#btnShow');
    },
    showModalUploadBackgroundPanelMobile() {
      this.$root.$emit('backgroundpanelmobile');
      this.$root.$emit('bv::show::modal', 'modal-upload', '#btnShow');
    },
    changeAvailableColor() {
      this.loading = true;
      let data = {
        id: 'available_color',
        value: this.available_color,
      };

      serviceMeta.postID(data).finally(() => (this.loading = false));
    },
    changeNotAvailableColor() {
      this.loading = true;
      let data = {
        id: 'not_available_color',
        value: this.not_available_color,
      };

      serviceMeta.postID(data).finally(() => (this.loading = false));
    },
    getAllCourses() {
      const url = 'list/';
      serviceCourse
        .read(url)
        .then((resp) => {
          this.AllCourses = resp;
        })
        .catch(() => {
          this.$root.$emit('loadOff');
        });
    },
  },
  mounted() {
    this.getKeys();
    this.getAllCourses();
    this.$root.$on('newlogopainel', (data) => {
      this.logo = data.url;
      this.updateLogoPainel();
    });
    this.$root.$on('newlogomarcapainel', (data) => {
      this.logomarca = data.url;
      this.updateLogomarcaPainel();
    });
    this.$root.$on('newbackgroundlogin', (data) => {
      this.login_background_image = data.url;
      this.login_background_image_id = data.id;
      this.updateBackgroundLogin();
    });
    this.$root.$on('newbackgroundpanel', (data) => {
      this.panel_background_image = data.url;
      this.panel_background_image_id = data.id;
      this.updateBackgroundPanel();
    });
    this.$root.$on('newbackgroundpanelmobile', (data) => {
      this.panel_background_image_mobile = data.url;
      this.panel_background_image_mobile_id = data.id;
      this.updateBackgroundPanelMobile();
    });
  },
};
</script>

<style lang="scss" scoped>
.item {
  width: 100%;
  display: grid;
  grid-template-columns: 30px 1fr 50px;
  margin-bottom: 20px;
  h6 {
    font-weight: 600;
    font-size: 16px;
    color: var(--fontcolor);
    margin-bottom: 12px;
  }
  span {
    font-weight: 400;
    font-size: 13px;
    color: #81858e;
    margin: 0;
  }
}

.vue-swatches__swatch {
  border-radius: 9px !important;
}

.inputSub {
  height: 40px;
  border: 1px solid #ededf0 !important;
  border-radius: 5px;
  font-weight: 400;
  font-size: 13px;
  color: #81858e;
  width: 40%;
  margin-top: 10px;
}

.base {
  width: calc(100% - 50px);
  padding-right: 50px;
}

.text {
  font-weight: 600;
  font-size: 13px;
  color: var(--fontcolor2);
  margin-bottom: 5px;
}

.flex {
  display: flex;
}

.icp__input {
  width: 42px !important;
  height: 42px !important;
  border-radius: 9px !important;
  background: #ffffff00 !important;
}

h1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: var(--fontcolor);
  margin-bottom: 25px;
}

.line {
  width: 100%;
  height: 1px;
  background: #ededf0;
  margin-bottom: 25px;
}

.input-file {
  border: 1px solid #ededf0 !important;
  padding: 13px;
  border-radius: 5px;
  pointer-events: none;
}

.image {
  cursor: pointer;
  height: 100px;
  border: 1px solid #ededf0 !important;
  padding: 13px;
  border-radius: 5px;
  overflow: hidden;
  img {
    object-fit: cover;
    height: -webkit-fill-available;
    border-radius: 2px;
  }
}

.black-selected {
  border: 2px solid white;
  &.icp__input[data-v-512dc202]::-moz-color-swatch {
    border-radius: 7px;
  }
}

.white-selected {
  border: 2px solid black;
  &.icp__input[data-v-512dc202]::-moz-color-swatch {
    border-radius: 7px;
  }
}
.font-v2 {
  font-family: Montserrat Alternates;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--fontcolor);
}
.cover-proportion-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.cover-proportion-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--bordercolor);
  margin-right: 15px;
  margin-top: 10px;
  border-radius: 6px;
  cursor: pointer;
}

.cover-proportion-text {
  color: #666; 
  text-align: center;
}

.cover-proportion-selected {
  border: 2px solid var(--maincolor) !important;
}
</style>
